.toolsContainer {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 8px;
}
.toolsContainer .postpone {
  flex: 1 auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
}
.toolsContainer .postpone .buttons {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
}
.toolsContainer .postpone .buttons :first-child {
  margin-bottom: 4px;
}
.toolsContainer .origins {
  flex: 1 auto;
  margin-left: 8px;
  display: flex;
  flex-flow: row wrap;
}
.toolsContainer .origins .content {
  display: flex;
  flex-flow: column nowrap;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9zcmMvdmlld3Mvc2hvcCIsInNvdXJjZXMiOlsiQnV5Rm9ybS5zYXNzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7RUFDQTtFQUNBOztBQUNBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7O0FBQ0E7RUFHRTtFQUNBO0VBQ0E7O0FBSkE7RUFDRTs7QUFJTjtFQUNFO0VBQ0E7RUFDQTtFQUNBOztBQUNBO0VBQ0U7RUFDQSIsInNvdXJjZXNDb250ZW50IjpbIi50b29sc0NvbnRhaW5lclxuICBkaXNwbGF5OiBmbGV4XG4gIGZsZXgtZmxvdzogcm93IHdyYXBcbiAgbWFyZ2luLWJvdHRvbTogOHB4XG4gIC5wb3N0cG9uZVxuICAgIGZsZXg6IDEgYXV0b1xuICAgIGRpc3BsYXk6IGZsZXhcbiAgICBmbGV4LWZsb3c6IHJvdyBub3dyYXBcbiAgICBqdXN0aWZ5LWNvbnRlbnQ6IHNwYWNlLWV2ZW5seVxuICAgIC5idXR0b25zXG4gICAgICA6Zmlyc3QtY2hpbGRcbiAgICAgICAgbWFyZ2luLWJvdHRvbTogNHB4XG4gICAgICBkaXNwbGF5OiBmbGV4XG4gICAgICBmbGV4LWZsb3c6IGNvbHVtbiBub3dyYXBcbiAgICAgIGp1c3RpZnktY29udGVudDogc3BhY2UtZXZlbmx5XG4gIC5vcmlnaW5zXG4gICAgZmxleDogMSBhdXRvXG4gICAgbWFyZ2luLWxlZnQ6IDhweFxuICAgIGRpc3BsYXk6IGZsZXhcbiAgICBmbGV4LWZsb3c6IHJvdyB3cmFwXG4gICAgLmNvbnRlbnRcbiAgICAgIGRpc3BsYXk6IGZsZXhcbiAgICAgIGZsZXgtZmxvdzogY29sdW1uIG5vd3JhcFxuIl19 */