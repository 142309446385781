import { Component } from 'react';
import PropTypes from 'prop-types'
import { Modal } from './bootstrap.js'
import { Autoform } from '../../autoform/Autoform.jsx'
import { renderModalButtons } from './ModalUtils'

export class ModalAutoform extends Component {
  componentDidMount() {
    this.updateInitialValues(this.props)
  }

  updateInitialValues(newProps) {
    // if (newProps.visible) {
    //   const values = newProps.initialValues
    //   if (values)
    //     newProps.initialize(values)
    // }
  }

  submitForm = () => {
    this.formComponent.submit()
  }

  handleSubmit = async (doc) => {
    await this.props.onSubmit(doc)
    this.props.onClose()
  }

  formName() {
    const { form, schema } = this.props

    return form || schema.getType() || 'def'
  }

  renderAutoform() {
    const formConfig = this.props.config || {
      horizontal: true,
      arrayMode: 'table'
    }

    if (this.props.visible) {
      return (
        <Autoform
          {...this.props}
          onSubmit={this.handleSubmit}
          schema={this.props.schema}
          config={formConfig}
          initialValues={this.props.initialValues}
          ref={e => this.formComponent = e}
          withRef
        />
      )
    } else
      return null
  }

  render() {
    const buttons = renderModalButtons({
      invalid: this.props.invalid,
      submitForm: this.submitForm,
      onClose: this.props.onClose,
    })

    return (
      <Modal
        title={this.props.modalTitle}
        visible={this.props.visible}
        onClose={this.props.onClose}
        buttons={buttons}
      >
        {this.props.prepend}
        {this.renderAutoform()}
        {this.props.append}
      </Modal>
    )
  }
}

ModalAutoform.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  schema: PropTypes.object.isRequired,
  form: PropTypes.string,
  config: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  modalTitle: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired
}
