import PropTypes from 'prop-types'
import { useMutation } from '../../graphql/notificatingMutation'

import {
  ModalAutoform,
  Button,
  Panel
} from '../../ui/bootstrap/bootstrap'

import { Buy } from '../../models/buy'
import { Ingredient } from '../../models/ingredient'
import {
  useGroups,
} from '../../models/ingredientGroup'
import { OriginsInfo } from './origins/OriginsInfo'
import { tr } from 'react-hook-form-auto'

import styles from './BuyForm.sass'

export const BuyForm = ({
  doc,
  onClose,
  visible,
  didSubmit,
  ingredient,
  onPostpone
}) => {
  const [ groups, loading ] = useGroups()
  const [ buyUpdate ] = useMutation(Buy.update)
  const [ ingredientUpdate ] = useMutation(Ingredient.update)

  const handlePostpone = (seconds) => {
    onPostpone(seconds)
  }

  const submit = (newDoc) => {
    const { groupId, ingredient, ingredientName } = newDoc

    if (groupId && groupId != doc.groupId) {
      ingredientUpdate({
        variables: {
          input: {
            id: ingredient.id,
            groupId
          }
        }
      })
    }

    const buyData = Buy.mutationConvert(newDoc)

    buyUpdate({
      variables: {
        input: buyData
      },
      optimisticResponse: {
        __typename: 'Mutation',
        buyUpdate: {
          ...buyData,
          __typename: 'Buy',
        }
      }
    })

    // Ingredient rename?
    if (ingredient) {
      const ingChanged = newDoc.ingredientName != doc.ingredient.name
      if (ingChanged) {
        ingredientUpdate({
          variables: {
            input: {
              id: ingredient.id,
              name: newDoc.ingredientName
            }
          }
        })

        // successText: tr('models.buys.renamed', {
        //   from: ingredient.name,
        //   to: newDoc.ingredientName
        // })
      }

      if (didSubmit)
        didSubmit(doc)
    }
  }

  const getTitle = () => {
    if (ingredient)
      return ingredient.name
    else
      return ""
  }

  // render()
  if (doc && groups) {
    const { ingredient } = doc
    const group = ingredient && ingredient.group
    const adaptedDoc = {
      ...doc,
      ingredientName: ingredient && ingredient.name,
      groupId: group && group.id
    }

    const schema = Buy.createBuySchema(groups)

    const toolsRendered = (
      <div className={styles.toolsContainer}>
        <Panel 
          header={tr('models.buy.postponeVerb')}
          className={styles.postpone}
        >
          <div className={styles.buttons}>
            <Button
              icon="hourglass"
              onClick={() => handlePostpone(60 * 60)}
              text={tr('models.buy.postponeOneHour')}
              type="primary"
            />
            <Button
              icon="hourglass"
              onClick={() => handlePostpone(60 * 60 * 24)}
              text={tr('models.buy.postponeOneDay')}
              type="primary"
            />
          </div>
        </Panel>
        <Panel
          header={tr('models.origins')}
          className={styles.origins}
        >
          <OriginsInfo buy={doc} />
        </Panel>
      </div>
        
    )

    return (
      <ModalAutoform
        schema={schema}
        onSubmit={submit}
        modalTitle={getTitle()}
        onClose={onClose}
        visible={visible}
        initialValues={adaptedDoc}
        prepend={toolsRendered}
      />
    )
  } else
    return null
}

BuyForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  doc: PropTypes.object,
  didSubmit: PropTypes.func
}
