import { createSchema, tr } from 'react-hook-form-auto'
import gql from 'graphql-tag'
import { useSubscription } from '@apollo/client'
import { useMutation } from '../graphql/notificatingMutation'

import { IngredientGroup } from './ingredientGroup'
import { valuesAsArray } from './units'
import { extractForSelect } from './utils'
import {
  createUpdater,
  createDeleteUpdater,
} from '../pintor'
import { ingredientFragment } from './ingredient'
import { mealFragment } from './meal'
import { OriginsInfo } from '../views/shop/origins/OriginsInfo'

export const BuyAmount = createSchema('buyAmount', {
  amount: {
    type: 'number'
  },
  unit: {
    type: 'select',
    options: valuesAsArray()
  }
})

export const createBuySchema = (groups) =>
  createSchema('buy', {
    ingredientName: {
      type: 'string'
    },
    groupId: {
      coerce: value => value === '' ? null : parseInt(value),
      type: 'select',
      options: extractForSelect(groups),
    },
    amounts: {
      type: [BuyAmount]
    }
  })

const buyFragment = gql`
  fragment BuyFragment on Buy {
    id
    ingredient {
      id
      name
      group {
        id
        name
      }
    }
    amounts {
      unit
      amount
    }
    origins {
      dietId
      mealIds
    }
    createdAt
    updatedAt
    postponedUntil
    postponed
  }
`

const BUYABLE_SEARCH = gql`
  query BuysSearch($search: String) {
    collection: buyableSearch(search: $search) {
      ... on Ingredient {
        ...IngredientFragment
      }
      ... on Meal {
        ...MealFragment
      }
    }
  }
  ${ingredientFragment}
  ${mealFragment}
`

const BUYS_GET = gql`
  query BuysList {
    buys {
      ...BuyFragment
    }
  }
  ${buyFragment}
`

const BUYS_SUB = gql`
  subscription BuyUpdate {
    buyChange {
      ...BuyFragment
    }
  }
  ${buyFragment}
`

const BUY_BUY_SUB = gql`
  subscription BuyBuySub {
    buyBuy
  }
`


const BUYS_COUNT = gql`
  query BuyCount($search: String) {
    count: buysCount(search: $search)
  }
`

const BUY_GET = gql`
  query BuyGet($id: ID!) {
    buy(id: $id) {
      ...BuyFragment
    }
  }
  ${buyFragment}
`

const BUY_UPDATE = gql`
  mutation BuyUpdate($input: BuyChangeInput!) {
    buyUpdate(input: $input) {
      ...BuyFragment
    }
  }
  ${buyFragment}
`

const BUY_ADD = gql`
  mutation BuyAddIngredient($input: BuyInput!) {
    buyAdd(input: $input) {
      ...BuyFragment
    }
  }
  ${buyFragment}
`

const useBuyAdd = () =>
  useMutation(Buy.methods.add, {
    refetchQueries: () => [ 'BuysList' ]
  })

const BUY_BUY = gql`
  mutation BuyPurchase($id: String!) {
    buyBuy(id: $id) {
      ...BuyFragment
    }
  }
  ${buyFragment}
`

const BOUGHT_BUY = gql`
  mutation BoughtAdd($id: String!) {
    boughtAdd(id: $id) {
      ...BuyFragment
    }
  }
  ${buyFragment}
`

const useBoughtAdd = () =>
  useMutation(Buy.methods.boughtAdd, {
    refetchQueries: () => [ 'JustBoughtList' ]
  })

const BUY_POSTPONE = gql`
  mutation BuyPostpone($id: String!, $seconds: Float) {
    buyPostpone(id: $id, seconds: $seconds) {
      ...BuyFragment
    }
  }
  ${buyFragment}
`

function formConvert(doc) {
  const { __typename, ...rest } = doc

  return {
    variables: {
      input: rest
    }
  }
}

function mutationConvert(doc) {
  const { id, amounts } = doc

  return {
    id,
    amounts: amounts.map(({ unit, amount }) => ({ unit, amount }))
  }
}

const findBuy = (buys, newBuy) =>
  data.buys.reduce((found, cur, idx) => {
    if (found == -1)
      return cur.id == newBuy.id ? idx : found
    else
      return found
  }, -1)

const dataUpdater = createUpdater({
  query: BUYS_GET,
  subscriptionName: 'buyChange',
  collectionKey: 'buys',
  filter: IngredientGroup.hasAmounts
})

export const useBuyUpdate = () => {
  const { data, loading } = useSubscription(BUYS_SUB, {
    onData: dataUpdater
  })
  const buyChange = data && data.buyChange

  return [ buyChange, loading ]
}

const dataDeleter = createDeleteUpdater({
  query: BUYS_GET,
  subscriptionName: 'buyBuy',
  collectionKey: 'buys',
  typename: 'Buy'
})

export const useBuyBuyUpdate = () => {
  const { data, loading } = useSubscription(BUY_BUY_SUB, {
    onData: dataDeleter
  })
  const buyBuy = data && data.buyBuy

  return [ buyBuy, loading ]
}

const boughtFragment = gql`
  fragment BoughtFragment on BoughtBuy {
    id
    createdAt
    ingredient {
      id
      name
    }
  }
`

const JUST_BOUGHT_GET = gql`
  query JustBoughtList {
    justBought {
      ...BoughtFragment
    }
  }
  ${boughtFragment}
`

const JUST_BOUGHT_SUB = gql`
  subscription BoughtAddition {
    boughtAddition {
      ...BoughtFragment
    }
  }
  ${boughtFragment}
`

const JUST_BOUGHT_DEL_SUB = gql`
  subscription BoughtDeletion {
    boughtDeletion
  }
`

const boughtDataUpdater = createUpdater({
  query: JUST_BOUGHT_GET,
  subscriptionName: 'boughtAddition',
  collectionKey: 'justBought'
})

export const useJustBoughtUpdate = () => {
  const { data, loading } = useSubscription(JUST_BOUGHT_SUB, {
    onData: boughtDataUpdater
  })
  const boughtAddition = data && data.boughtAddition

  return [
    boughtAddition,
    loading
  ]
}

export const Buy = {
  name: 'buy',
  createBuySchema,
  search: BUYABLE_SEARCH,
  fetch: BUYS_GET,
  sub: BUYS_SUB,
  count: BUYS_COUNT,
  get: BUY_GET,
  update: BUY_UPDATE,
  methods: {
    add: BUY_ADD,
    buy: BUY_BUY,
    boughtAdd: BOUGHT_BUY,
    buyPostpone: BUY_POSTPONE
  },
  useBuyAdd,
  useBoughtAdd,
  fragment: buyFragment,
  formConvert,
  mutationConvert,
  useBuyUpdate,
  useBuyBuyUpdate,
  mainSubName: 'buyChange',
  mainSubCollection: 'buys',

  fetchJustBought: JUST_BOUGHT_GET,
  subJustBought: JUST_BOUGHT_SUB,
  useJustBoughtUpdate,
}
