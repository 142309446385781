import { tr } from 'react-hook-form-auto'
import classnames from 'classnames'

import { useOrigins } from './useOrigins'
import styles from './OriginsInfo.sass'

// FIXME unstructured
const renderSomeDiets = diets =>
  diets && diets.map(diet =>
    <div key={diet.id}>{diet.name}</div>
  ) || null

// FIXME unstructured
const renderSomeMeals = meals =>
  meals && meals.map(meal =>
    <div key={meal.id}>{meal.name}</div>
  ) || null


const renderBlocks = (buy, someDiets, someMeals) => {
  const secondClasses = classnames(
    styles.title,
    styles.sepTitle
  )

  return (
    <div key={buy.id}>
      {
        someDiets && someDiets.length > 0 &&
          <div>
            <div className={styles.title}>
              {tr('models.diets')}
            </div>
            <div className={styles.section}>
              {renderSomeDiets(someDiets)}
            </div>
          </div>
      }
      {
        someMeals && someMeals.length > 0 &&
          <div>
            <div className={secondClasses}>
              {tr('models.meals')}
            </div>
            <div className={styles.section}>
              {renderSomeMeals(someMeals)}
            </div>
          </div>
      }
    </div>
  )
}

export const OriginsInfo = ({ buy }) => {
  const { someDiets, someMeals } = useOrigins(buy)

  return (
    <div className={styles.list}>
      {renderBlocks(buy, someDiets, someMeals)}
    </div>
  )
}
